.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
  margin-top: 3rem;
}

.details .projectionStack {
  margin: 2rem 0px;
}

.details .lorenz-example {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.details .lorenz-example img {
  margin: -15% 0px;
}
