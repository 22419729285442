.fullmint {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 800px;
}

.fullmint .main-interactive-container {
  margin: 50px 0;
}

.fullmint .mint-button {
  font-size: 1.5rem;
  padding: 1rem;
}

/* .fullmint div {
  margin: 100px 0;
} */

.fullmint h2 {
  margin-top: 100px;
  margin-bottom: 50px;
}

.fullmint .token-selector {
  margin: 5px;
}

.fullmint .token-selector-container {
  margin: 5px;
}
