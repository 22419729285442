.mint {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
}

.mint .textblock {
  margin-top: 5vmin;
  text-align: left;
}

.timer {
  margin: 2.5rem 0px;
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
}



.mint h4 {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.mint .important-numbers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.mint .important-numbers ul {
  width: 280px;
}

.mint .flex-col {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.flex-col div {
  width: 50%;
}


@media screen and (max-width: 800px) {
  .mint .flex-col {
    flex-direction: column;
    gap: 0px;
  }
  .mint .important-numbers {
    flex-direction: column;
  justify-content: center;
  }
}

.mint .info li:not(:last-child) {
  margin-bottom: 1rem;
}

.mint-section {
  text-align: center;
  margin-top: 50px;
}


.mint-section > div {
  margin: 2rem 0px;
}


.available-slots {
  margin-bottom: 10px;
}

.mint-button {
  font-size: 2rem;
  padding: 1rem;
}


.disclaimer {
  margin-top: 4rem;
}


.mint hr {
  margin-top: 4rem;
}

