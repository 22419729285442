.tools {
  width: 1000px;
  align-items: center;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-number {
  font-family: "DMMono";
  background-color: black;
  color: white;
  border: 1px solid #aaaaaa;
  width: 50px;
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
.slider-number::-webkit-outer-spin-button,
.slider-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.slider-number[type="number"] {
  -moz-appearance: textfield;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  flex-grow: 1;
  margin: 2px;
  /* width: 100%; Full-width */
  height: 10px; /* Specified height */
  background: #444444; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: white; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0px 0px 0px 0px;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: white; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.svg-window {
  background-color: black;
  aspect-ratio: 1;
  border: 1px solid white;
}

.rendering-indicator {
  z-index: 10;
  position: absolute;
  margin: 2rem;
  height: 1rem;
}

input[type=button] {
  /* font-weight: 500; */
  font-size: 1.15em;
}


.paletteTitle {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 30px;
}

.border {
  border: 1px solid white;
}

.projectionCustomizer {
  /* margin-top: 50px; */
}

.projectionCustomizer-tools {
  display: flex;
  justify-items: center;
}

.projectionSliders {
  padding: 10px;
  flex-grow: 1;
}

.labeled-slider {
  display: flex;
}

.labeled-slider .slider-label {
  display: inline-block;
  width: 75px;
  text-align: end;
  margin-right: 10px;
}

.labeled-slider .slider-slider {
  display: inline-block;
  flex-grow: 1;
}

.projectionExpert {
  flex-grow: 1;
  padding: 10px;
  padding-top: 0px;
}

.projectionExpert input[type="number"] {
  margin: 5px;
  width: 70px;
}

.array-input-label {
  display: inline-block;
  /* margin: 0px 20px; */
  width: 100px;
}

.array-column-label {
  display: inline-block;
  /* margin: 0px 20px; */
  width: 76px;
  margin: 5px;
}

.tools .wallet-connect {
  margin: 70px;
}

.token-selector {
  height: 2.3rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.token-selector select {
  font-size: 1rem;
}

.button-box {
  display: flex;
}

.button-box div {
  flex: 1;
  border: 1px solid white;
}

.tools-container {
  display: flex;
}


.tools-container .left {
  flex: 1;
}

.tools-container .right {
  flex: 1;
  display: flex;
  flex-flow: column;
}


.colormap {
  flex-grow: 1;
}

.disabled {
  opacity: 0.2;
}

.rinkeby-message {
  margin-top: 3rem;
  margin-bottom: 4rem;
}