.colormap-creator {
  /* width: 100%; */
  padding: 10px;
}

.colormap-sliders {
}

.colormap-slider-row {
  display: flex;
  justify-items: center;
  align-items: center;
}

.colormap-slider-row .remove-slider-button {
  display: inline-block;
  width: 25px;
  height: 23px;
  margin: 0px;
  border: none;
  padding: 0px;
  margin-right: 5px;
  vertical-align: center;
}

.colormap-slider-row .color-picker {
  display: inline-block;
  width: 40px;
  height: 25px;
  border: none;
  margin: -1px;
  margin-right: 10px;
  padding: 0px;
}

.colormap-slider-row .position-slider {
  display: inline-block;
  flex-grow: 1;
}

.colormap-preview {
  display: flex;
  border: 1px solid white;
  /* margin-left: 62px; */
  /* margin-bottom: 10px; */
  /* margin-top: 10px; */
}

.preview-color {
  display: inline-block;
  flex: 1;
  height: 10px;
}


.colormap-point {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .preview-row {
      display: flex;
      justify-items: center;
      align-items: center;
  }

.preview-row .add-slider-button {
  display: inline-block;
  width: 25px;
  height: 23px;
  margin: 0px;
  border: none;
  padding: 0px;
  margin-right: 5px;
  vertical-align: center;
}

.preview-row .colormap-preview-container {
    display: inline-block;
    flex: 1;
}