.quest {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
}

.quest .img-container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
  margin: 5vmin 0 3vmin;
}

.quest img {
  margin: 0px;
  width:100%;
  max-width: 500px;
}

.quest .equation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.quest .equation-container .equation {
  margin: 10px;
}

.quest .table-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.quest .table {
  max-width: 95vw;
  width: 350px;
  text-align: center;
  font-size: 0.8rem;
}

.quest .table thead tr th:first-child,
.quest .table tbody tr td:first-child {
  width: 80em;
  min-width: 80em;
  max-width: 8em;
  word-break: break-all;
}