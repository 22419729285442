.home {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.home .social-media-links {
  margin-top: 5rem;
  margin-bottom: -4rem;
}

.home .social-media-links > span {
  display:inline-block;
}

.home .social-media-links span {
  margin: 10px;
}

.home .releaseDate {
  margin-top: 4rem;
}

.releaseDate .timer {
  margin: 1.5rem;
  font-size: 3rem;
  font-weight: 300;
}

input {
  background-color: black;
  color: white;
  font-family: "DMMono";
}

.lottery-button {
  font-size: 2rem;
  padding: 1rem;
  border: 1px solid #a0a0a0;
  margin: 5px;
}

.home .cover-img {
  width: 720px;
  margin-top: -60px;
  margin-bottom: -60px;
}

@media only screen and (max-width: 768px) {
  .home .cover-img {
    width: 95vmin;
    margin-top: 0px;
    margin-bottom: -5vmin;
  }
}

.lorenzSystem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.lorenzProjection {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
  width: 100%;
}

.projectionStack {
  margin: 20px 0px;
}
