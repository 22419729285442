.quest2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
}

.quest2 .img-container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
  margin: 5vmin 0 3vmin;
}

.quest2 img {
  margin: 50px 0px 30px;
  width:80%;
  max-width: 400px;
}

.quest2 .letter {
  font-style: italic;
  margin-left: 20px;
}
