body {
  margin: 0;
  font-family: "DMMono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

@font-face {
  font-family: "DMMono";
  font-weight: 400;
  src: local("DMMono"),
    url(./assets/fonts/DMMono/DMMono-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "DMMono";
  font-weight: 500;
  src: local("DMMono"),
    url(./assets/fonts/DMMono/DMMono-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "DMMono";
  font-weight: 300;
  src: local("DMMono"),
    url(./assets/fonts/DMMono/DMMono-Light.ttf) format("truetype");
}
