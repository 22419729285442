.App {
  text-align: center;
  font-size: 0.95rem;
  font-weight: 100;
}

.App a {
  text-decoration: none;
  color: white;
}

/* .App a:hover {
  color: #84bcd9;
  text-decoration: underline;
} */

.content {
  color: white;

  display: flex;
  flex-flow: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 20px 5px;

  align-items: center;
  /* background-color: blue; */
}

.content .navbar {
  flex: 0 1 auto;
  width: 100%;
  z-index: 2;
}

.content .main-content {
  flex: 1 1 auto;
  z-index: 1;

  display: flex;
  flex-flow: column;

  width: 100%;
  /* max-width:1000px; */
  /* background-color: green; */
}

.content .footer {
  flex: 0 1 auto;
  width: 100%;
  z-index: 2;
}

.content .textblock {
  width: 800px;
  max-width: 99vw;
  line-height: 1.45rem;
}

.content .textblock a {
  text-decoration: underline;
}

.content .textblock strong {
  font-weight: bold;
}

li {
  list-style-type: '> ';
}


.main-content {
  align-items: center;
}

input {
  background-color: black;
  color: white;
  font-family: 'DMMono';
}

input[type=button] {
  border:1px solid #a0a0a0;
  margin: 5px;
}

input[type="button"]:enabled:hover {
  background-color: white;
  color: black;
}

input[type="button"]:enabled:active {
  color: white;
  background-color: black;
}

input[type=button]:disabled{
  color: #888888;
  text-decoration: line-through;
}

input[type=number] {
  background-color: black;
  color: white;
  border:1px solid #aaaaaa;
  width: 50px;
  text-align: right;

}

/* remove number arrows */
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number][type=number] {
  -moz-appearance: textfield;
  list-style-type: '> ';
}

select {
  position: relative;
  font-family: "DMMono";
  background-color: black;
  color: white;
}

h2 {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
  line-height: 2rem;
}

.App h4 {
  font-weight: 500;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}