.admin {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  border-spacing: bet;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 800px;
}

.admin div {
  margin: 20px;
}