.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: left;
}

.about .gallery {
  max-width: 1300px;
  width: 100%;
  margin-top: 50px;
}

.about .gallery img {
  width: 100%;
}

.about .elevator {
  font-weight: 500;
}

.about .centered {
  text-align: center;
}
