.navbar {
  display: flex;

  /* font-size: small; */
  font-weight: lighter;
  line-height: 1.7rem;
}

.navbar .title {
  font-weight: 500;
  font-size: 1.7rem;
  margin: 0px;
}

.navbar .left {
  float: left;
  width: 25%;
  text-align: left;
}

.navbar .center {
  float: left;
  width: 50%;
  text-align: center;
}

.navbar .right {
  float: left;
  width: 25%;
  text-align: right;
}
